body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button{

}

.note {
  width: 50px; 
  height: 200px;
  padding: 10px;
  margin: 1px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.note.sharp {
  background-color: black;  /* Darker for sharp notes */
  color: white;
}

.note.flat {
  background-color: black; /* Blue background for flat notes */
  color: white;
}
.played-notes{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: black;
  color: white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.played-notes span{
  margin-right: 5px;
}

.note.active {
  animation: playNoteAnimation 0.3s ease;
}

@keyframes playNoteAnimation {
  0% { background-color: yellow; box-shadow: 5px 5px 5px yellow; }
  100% { background-color: inherit; box-shadow: unset; }
}
.clear-notes{
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}